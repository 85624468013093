@import url("https://fonts.googleapis.com/css?family=Open+Sans&display=swap");

html,
body,
#root {
  height: 100%;
}
.App {
  text-align: center;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Open Sans", "Avenir", sans-serif;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}
#mainContent {
  overflow-y: scroll;
  background: white;
  display: flex;
  flex-direction: column;
}
@media print {
  .App-header,
  .mainToolbar,
  th span {
    display: none !important;
  }
  body {
    font-size: 50%;
  }
  #mainContent {
    overflow-y: visible !important;
    display: block !important;
    height: "100%";
  }
  div {
    display: block !important;
  }
}
th.phone {
  white-space: nowrap;
}

.mainToolbar {
  padding: 10px 1%;
  width: 98%;
  max-width: 100%;
  background: #162f53;
  color: #fff;
  font-size: 15px;
  font-weight: bold;
  display: flex;
  min-height: 35px;
  max-height: 35px;
}
.navlinks a{
  display: inline-block;
  color:#ccc;
  font-weight:700;
  margin:8px 8px;
  padding-bottom:5px;
  font-size:15px;
  line-height: 15px;
  text-decoration: none;
  font-variant: small-caps;
}
.navlinks a:hover{
  color:#eee;
  border-bottom:thin dotted #eee;
}
.navlinks a.active{
  color:#fff;
  border-bottom:thin solid #fff;
}


table {
  border-spacing: 0;
  border: 1px solid black;
  page-break-inside: auto;
}
th,
td {
  /* width: 15%;
    min-width: 200; */
  margin: 0;
  padding: 0.5rem;
  border-bottom: 1px solid black;
  border-right: 1px solid black;
}
th {
  cursor: pointer;
}
th:last-child,
td:last-child {
  border-right: 0;
}
tr {
  page-break-inside: avoid;
  page-break-after: auto;
}

tr:last-child td {
  border-bottom: 0;
}
