@import url("https://fonts.googleapis.com/css?family=Nunito:200,300,400,600,800");

#root {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Avenir", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}

.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 80px;
}

.App-header {
  /* background: linear-gradient(to bottom, #444,#222); */

  height: 150px;
  padding: 20px;
  color: white;
}

.App-title {
  font-size: 1.5em;
}

.App-intro {
  font-size: large;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}
/* .AppBar {
	display: flex;
	flex-direction: row;
	flex-wrap: nowrap;
	align-items: flex-end;
	background: #e4e5ec;
} */
.tabBar {
  /* user-select: none; */
  margin: 0px;
  padding: 0px 0px;
  height: 45px;
  border-bottom: solid 0px #fff;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: flex-end;
  -webkit-app-region: drag;
  background: #e4e5ec;
}
.tabBarFlexContainer {
  vertical-align: bottom;
}
/* .tabBar.Mac {
	padding-left: 10px;
	height: 38px;
}

.tabBar.iOS {
	height: 60px;
}

.tabBar.Android {
	padding-left: 0px;
	height: 40px;
} */

#root > div > .ant-tabs > .ant-tabs-content {
  flex-grow: 1;
}

@keyframes expand {
  from {
    transform: scale(1, 0.7);
    transform-origin: bottom;
  }
}

.caserTabButton {
  background: #d1d5e2;
  padding: 0px;
  font-family: "roboto";
  font-size: 14px;
  letter-spacing: 0.5px;
  font-weight: 400;
  display: inline-block;
  color: #37474f;
  cursor: pointer;
  line-height: 44px;
  height: 45px;
  vertical-align: middle;
  user-select: none;
  z-index: 1;
  /* min-width: 60px; */
  flex-grow: 0;
  /* max-width: 225px; */
  display: flex;
  flex-direction: row;
  align-items: center;
  -webkit-app-region: no-drag;
  /* border-top-left-radius: 7px;
	border-top-right-radius: 7px; */
  animation: expand 0.7s cubic-bezier(0.19, 1, 0.22, 1);
  transition: background-color 0.1s, width 0.5s;
  text-overflow: ellipsis;
  margin-top: 7px;
}
.caserTabButton-Pinned {
  padding: 0px 4px;
  /* margin:0px; */
  flex-grow: 0;
  min-height: 45px;
  /* display: inline-block; */
}
.caserTabButton:hover {
  background: #bfc4d1;
}

/* .tabBar.Android .caserTabButton {
	border-radius: 0px;
	flex-grow: 0;
	max-width: auto;
	width: auto;
	height: 40px;
}

.caserTabButtonBefore,
.caserTabButtonAfter {
	position: relative;
	width: 10px;
	height: 10px;
	margin: 0;
	overflow: hidden;
	background: transparent;
}

.caserTabButtonBefore:after,
.caserTabButtonAfter:after {
	content: "";
	position: absolute;
	left: 0px;
	top: 0px;
	border-radius: 0;
	width: 10px;
	height: 10px;
	box-shadow: 0px 0px 0px 10px #d0d4da;
}

.tabBar.Android .caserTabButtonBefore::after,
.tabBar.Android .caserTabButtonAfter {
	display: none;
}

.caserTabButtonBefore:after {
	border-bottom-right-radius: 7px;
}

.caserTabButtonAfter:after {
	border-bottom-left-radius: 7px;
}

.caserTabButtonBefore[sel="true"]:after,
.caserTabButtonAfter[sel="true"]:after {
	box-shadow: 0px 0px 0px 10px #fff;
	z-index: 4;
}
 */
/* 
.caserTabButtonBefore,
.caserTabButtonAfter {
  height:10px;
  width:10px;
  z-index: 0;
  background:#dee1e6;
}
.caserTabButtonBefore > div,
.caserTabButtonAfter>div {
  background: #dee1e6;
} */
.caserTabButtonBefore {
  margin-left: -10px;
}

.caserTabButtonAfter {
  margin-right: -10px;
}

::-webkit-scrollbar {
  width: 1px;
  height: 0px;
  /* remove scrollbar space */
  background: transparent;
  /* optional: just make scrollbar invisible */
}

::-webkit-scrollbar-thumb {
  background: rgba(51, 51, 51, 0.26);
}

.tabBar .caserTabButton[sel="true"] {
  background: #fff;
  z-index: 4;
}

/*
.caserTabButtonBefore[sel="true"],
.caserTabButtonAfter[sel="true"] {
  background:#fff;
  z-index: 4;
}
.caserTabButtonBefore[sel="true"][fir="false"] > div {
  background: #d0d4da;
}
.caserTabButtonAfter[sel="true"][lst="false"] > div {
  background: #d0d4da;
} */

.caserTabAdderButton {
  /* margin: 2px;
	border-radius: 14px;
	color: #37474f;
	width: 28px;
	height: 28px;
	padding: 4px;
	z-index: 100;
	cursor: pointer;
	background: transparent;
	transition: background 0.5s;
	-webkit-app-region: no-drag; */
  margin-left: 10px;
  margin-top: 12px;
}

/* .caserTabAdderButton:hover {
	background: #90a4ae;
}

.caserTabAdderButton .add {
	line-height: 23px;
	height: 23px;
} */

.caserAdderButton {
  margin: 2px;
  border-radius: 14px;
  color: #555;
  width: 28px;
  height: 28px;
  padding: 4px;
  z-index: 100;
  cursor: pointer;
  background: #dee1e6;
  transition: background 0.1s;
  -webkit-app-region: no-drag;
}

.caserAdderButton:hover {
  background: #c7cbd1;
}

.caserTabButton.remove:hover,
.caserTabButton.add:hover {
  background-color: transparent;
}

.pageShell {
  position: relative;
  flex-grow: 1;
  min-height: 50px;
  background: #e9edf0;
}

.pageShell > div {
  background: #fff;
  position: absolute;
  height: 100%;
  width: 100%;
  z-index: 0;
  /* display: none; */
  visibility: hidden;
}

.pageShell > div[sel="true"] {
  z-index: 10;
  display: block;
  visibility: visible;
  background: #fff;
}

.elementHeader {
  min-height: 55px;
  height: 55px;
  background: #ffffffc9;
  border: none;
  /* margin-bottom: 5px; */
}

div.my-drawer {
  overflow: scroll;
  -webkit-overflow-scrolling: touch;
  border: none;
  background: #fff;
  padding: 0px;
  border: none;
  flex: 0 0 320px;
}

.my-drawer .ant-layout-sider-children {
  margin: 0px;
  padding: 0px;
  width: 320px;
}

.my-drawer .am-drawer-content {
  overflow: hidden;
}

/*Caser List*/
.caser-list-container {
  display: flex;
  position: relative;
  min-height: 1px;
  flex-grow: 1;
  flex-shrink: 1;
  flex-direction: column;
  height: 100%;
  padding: 2px;
}
.caser-listHeader-container {
  z-index: 2;
  flex-shrink: 0;
  flex-grow: 0;
  min-height: 36px;
  background: #ccd3e0;
  overflow: hidden;
  border-radius: 7px;

  /* box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.4); */
}

.caser-listHeader-container-inner {
  font-size: 16px;
  height: inherit;
  overflow-x: scroll;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
}
.caser-listFooter-container {
  z-index: 2;
  flex-shrink: 0;

  flex-grow: 0;
  min-height: 36px;
  background: #ccd3e0;
  overflow: hidden;
  border-radius: 7px;

  /* box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.4); */
}

.caser-listFooter-container-inner {
  font-size: 16px;
  height: inherit;
  overflow-x: scroll;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
}

.caser-listContent-container {
  flex-grow: 1;
  flex-shrink: 1;

  background: #fff;
  overflow-y: scroll;
  min-height: 1px;
  margin: 0px 5px;
  border: thin solid #dbdbd5;
  border-top: none;
  border-bottom: none;
}

.caser-listContent-loadingContainer {
  flex-grow: 1;
  flex-shrink: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #eee;
  overflow: hidden;
  min-height: 1px;
  margin: 0px 5px;
}

.caser-list-item {
  z-index: 1;
  transition: background-color 0.45s cubic-bezier(0.19, 1, 0.22, 1);
  color: #000;
  margin: 0px;
  padding: 10px 3px;
  border-bottom: solid #dbdbd5 1px;
  cursor: pointer;
}
.caser-list-item:hover {
  background-color: #eee;
}
.caser-list-item.selected {
  background-color: #e7e6d8;
}

.caser-list-container.rounded {
  padding: 5px;
}
.caser-list-container.rounded .caser-listHeader-container {
  border-radius: 12px;
  margin-bottom: -12px;
}
.caser-list-container.rounded .caser-listFooter-container {
  border-radius: 12px;
  margin-top: -12px;
}
.caser-list-container.rounded .caser-listContent-container,
.caser-list-container.rounded .caser-listContent-loadingContainer {
  padding: 12px 0px;
  margin: 0px 7px;
}
/*Caser Page Tabs*/
.caser-pageTabs-container {
  display: flex;
  position: relative;
  min-height: 1px;
  flex-grow: 1;
  width: 100%;
  height: inherit;
  flex-direction: column;
}
.caser-pageTabs-tabbar-container {
  z-index: 2;
  /* box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.4); */
  font-family: -apple-system-caption1, BlinkMacSystemFont, "Segoe UI", "Avenir",
    Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  flex-grow: 0;
  flex-shrink: 0;
  margin: 0px;
  height: 42px;
  min-height: 42px;
  background: #e8e8e8;
  overflow: hidden;
}

.caser-pageTabs-tabbar-container-inner {
  font-size: 15px;
  height: inherit;
  overflow-x: scroll;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  padding: 0px 2px;

  /* border-radius: 18px; */
}

.caser-pageTabs-tabbar-element {
  flex-grow: 0;
  padding: 5px 10px;
  margin: 0px 2px;
  border: solid #e8e8e8 2px;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  border-radius: 17px;
  /* border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px; */
  font-weight: 600;
  transition: background-color 1s cubic-bezier(0.19, 1, 0.22, 1),
    border-color 1s cubic-bezier(0.19, 1, 0.22, 1);
  user-select: none;
}
.caser-pageTabs-tabbar-element.pinned {
  padding: 5px;
}
.caser-pageTabs-tabbar-element:hover {
  background-color: #ccc;
  border-color: #ccc;
}
.caser-pageTabs-tabbar-element .MuiButtonBase-root {
  opacity: 1;
  transform: scale(0.1);
  margin: -2px -10px;
  padding: 0px;
  transition: transform 0.2s, margin 0.2s, padding 0.2s, width 0.2s,
    opacity 0.2s;
}

.caser-pageTabs-tabbar-element:hover .MuiButtonBase-root {
  opacity: 1;
  padding: 3px;
  transform: scale(1);
  margin: -2px 5px;
}

@media (pointer: coarse) {
  .caser-pageTabs-tabbar-element .MuiButtonBase-root {
    opacity: 1;
    padding: 3px;
    transform: scale(1);
    margin: -2px 5px;
  }
}
.caser-pageTabs-tabbar-element.open {
  background-color: #fff;
  border-color: #fff;
  color: #03396c;
}

.caser-pageTabs-tabcontent-container {
  flex-grow: 1;
  flex-shrink: 1;
  margin: 0px 0px;
  min-height: 1px;
  position: relative;
}
.caser-pageTabs-tabcontent-container-inner {
  margin: 0px 0px;
  height: 100%;
  width: 100%;
  position: relative;
}

.caser-pageTab-element {
  position: relative;
  z-index: 1;
  opacity: 0;
  color: #000;
  opacity: 0;
  position: absolute;
  height: 100%;
  width: 100%;
  background: #fff;
  overflow-y: scroll;
}

.caser-pageTab-element.open {
  /* display: block; */
  transition: opacity 0.5s;
  z-index: 2;
  opacity: 1;
  height: 100%;
}
/*Caser Tabs*/
.caser-tabs-container {
  display: flex;
  position: relative;
  min-height: 1px;
  flex-grow: 1;
  width: 100%;
  flex-direction: column;
  padding: 0px;
}
.caser-tabs-container.bottom {
  flex-direction: column-reverse;
}
.caser-tabbar-container {
  z-index: 2;
  /* box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.4); */
  font-family: -apple-system-caption1, BlinkMacSystemFont, "Segoe UI", "Avenir",
    Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  flex-grow: 0;
  height: 36px;
  min-height: 36px;
  border-radius: 18px;
  background: #eee;
  overflow: hidden;
  margin-bottom: 0px;
}
.caser-tabs-container.bottom .caser-tabbar-container {
  flex-grow: 0;
  height: 36px;
  min-height: 36px;
  border-radius: 18px;
  background: #ddd;
  overflow: hidden;
  margin-top: 0px;
}

.caser-tabbar-container-inner {
  font-size: 15px;
  height: inherit;
  overflow-x: scroll;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
}

.caser-tabbar-element {
  flex-grow: 0;
  padding: 5px 10px;
  margin: 0px 2px;
  border: solid transparent 1px;
  cursor: pointer;
  border-radius: 18px;
  transition: background-color 1s cubic-bezier(0.19, 1, 0.22, 1),
    border-color 1s cubic-bezier(0.19, 1, 0.22, 1);
}
.caser-tabbar-element:hover {
  border-color: #03396c;
}
.caser-tabbar-element.open {
  background: #fff;
  border-color: #0d4fc9;
  color: #03396c;
}

.caser-tabcontent-container {
  flex-grow: 0;
  flex-shrink: 1;
  margin: 0px 20px;
  padding: 5px;
  border-bottom-left-radius: 7px;
  border-bottom-right-radius: 7px;
  min-height: 1px;
  position: relative;
  overflow-y: scroll;
  border: thin solid #ccc;
  border-top: none;
}
.caser-tabs-container.bottom .caser-tabcontent-container {
  flex-grow: 1;
  margin: 0px 15px;
  margin-top: 10px;
  background: #fafafa;
  border-radius: 0px;
  border-top-left-radius: 7px;
  border-top-right-radius: 7px;
  min-height: 1px;
}

.caser-tab-element {
  z-index: 1;
  opacity: 0;
  height: 0;
  flex-grow: 0;
  flex-shrink: 0;
  width: 100%;
  transition: opacity 0.45s;
  color: #000;
  margin: 0px;
  padding: 0px;
  position: relative;
  overflow-y: scroll;
}

.caser-tab-element.open {
  height: 100%;
  opacity: 1;
  overflow-y: scroll;
}

/*Tabs Style*/
.ant-tabs.innerTabs {
  display: flex;
  flex-direction: column;
  height: 0px;
  width: 100%;
  flex-grow: 1;
  flex-shrink: 0;
  margin: 0px auto;
  /* max-width: 1000px; */
}

.innerTabs > .ant-tabs-bar {
  flex-grow: 0;
  flex-shrink: 0;
  border-radius: 5px;
  margin: 15px;
  background: #fff;
  /* border: solid 1px #2D7D9A; */
  /* background: #3498db; */
  overflow: visible;
  margin-bottom: 10px;
  font-weight: 600;
  padding: 0px 5px;
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0);
  height: 30px;
  border: 1px solid #bbb;
}
.ant-tabs-nav-wrap {
  overflow: visible;
}
.innerTabs > .ant-tabs-bar .ant-tabs-nav-wrap,
.innerTabs > .ant-tabs-bar .ant-tabs-nav-scroll {
  overflow: visible;
}

.innerTabs > .ant-tabs-bar .ant-tabs-nav-container {
  margin: 0;
  border: none;
  overflow: visible;
}

.innerTabs > .ant-tabs-bar .ant-tabs-tab-prev-icon,
.innerTabs > .ant-tabs-bar .ant-tabs-tab-next-icon {
  color: #000;
}
.innerTabs .ant-tabs-tab-prev.ant-tabs-tab-btn-disabled.ant-tabs-tab-arrow-show,
.innerTabs .anticon.anticon-right.ant-tabs-tab-next-icon-target {
  z-index: 0;
}
.innerTabs > .ant-tabs-bar .ant-tabs-tab {
  padding: 6px 6px;
  user-select: none;
  margin: -4px 4px;
  margin-bottom: 0px;
  font-size: 15px;
  letter-spacing: 1px;
  color: #444;
  border-radius: 0px;
  font-weight: 600;
  border: thin solid #00000000;
  transition: color 0.2s;
  border-radius: 5px;
  height: 37px;
  /* transition: background-color 0.2s, color 0s; */
}

.innerTabs > .ant-tabs-bar .ant-tabs-tab:hover {
  color: #444;
  background: #ffffff;
  border-color: #03396c;
}

.innerTabs > .ant-tabs-bar .ant-tabs-tab-active,
.innerTabs > .ant-tabs-bar .ant-tabs-tab-active:hover {
  /* background: #2980b9;*/
  color: #fff;
  /* border-color: #1e88e5; */
  background: radial-gradient(to center, #2196f3 0%, #1976d2 100%);
  border-radius: 5px;
  background: #03396c;
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.25);
}

.innerTabs .ant-tabs-ink-bar {
  opacity: 0;
  display: none;
}

.innerTabs > .ant-tabs-content {
  overflow: scroll;
  -webkit-overflow-scrolling: touch;
  flex-grow: 1;
  margin-top: 0px;
}

/* Lists */

.ant-list > div {
  flex-grow: 1;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
}

.ant-list-split.ant-list > div {
  background: #efefef;
}

div.ant-spin-nested-loading {
  /* scrollbar-width:none; */
}

.ant-list > div.ant-list-header {
  padding: 0px;
  flex-grow: 0;
  flex-shrink: 0;
  border-bottom: none;
  background: #fff;
}

div.ant-spin-blur {
  opacity: 0;
}
.ant-list-split.ant-list > .ant-list-footer {
  padding: 0px;
  flex-grow: 0;
  flex-shrink: 0;
  border-top: solid 1px #777;
  background: #f3f3f3;
}

/* .ant-list .ant-list-item {
  border: none;
} */

/* List Item*/

.ant-list-split ul.ant-list-items > .ant-list-item.listItem {
  color: #000;
  transition: background-color 0.2s, border-color 0.3s;
  cursor: pointer;
  padding: 10px 5px;
  margin: 0px 0px;
  margin-right: 0px;
  flex-direction: column;
  text-align: start;
  border: solid #00000000 1px;
  border-left: none;
  border-right: none;
  background: #fbfcfd;
}

.ant-list-item.listItem:first-child {
  margin-top: 10px;
}
.ant-list-item.listItem:last-child {
  margin-bottom: 10px;
}

/* .ant-list-split li.ant-list-item {
	border-top-color: #00000000;
	border-bottom-color: #00000000;
} */

.listItem > div {
  display: block;
  width: 100%;
}
.listItem.header {
  background: #444;
  color: #fff;
  padding: 3px;
}
.ant-list-split
  ul.ant-list-items
  > li.ant-list-item.listItem.listItem:hover:not(.selected) {
  border-color: #00000000;
  background: #eee;
}

.ant-list-split ul.ant-list-items > li.ant-list-item.listItem.selected {
  border-color: #00000000;
  background: #e7e6d8;
}

/* List Item Extra*/
.caser-list-item-extra-container {
  /* background: #ffffff; */

  padding: 0px;
  padding-left: 5px;
  border-radius: 5px;
  overflow: hidden;
  font-weight: 600;
  color: #166faa;
  /* margin: 5px 5px; */
  /* border: solid thin #b0bec5be;
  box-shadow: 0px 0px 7px 0px #d4d4d4; */
}

.caser-list-item-extra-title {
  /* background: #CFD8DC; */
  border-radius: 0px 0px 0px 0px;
  font-weight: 600;
  text-align: left;
}

.caser-list-item-extra-body {
  padding: 0px 0px;
  text-align: left;
}

/* WeekTabs */
.weekTabs {
  flex-grow: 1;
  padding: 3px 1px;
  margin: 3px 2px;
  background: #ddd;
  color: #111;
  border-radius: 3px;
  font-size: 11px;
  text-align: center;
  vertical-align: middle;
  line-height: 13px;
  cursor: pointer;
  transition: background 0.5s;
}

.weekTabs.current {
  background: #eee;
  color: #162557;
}

/* Select */

.ant-select-open.ant-select-selected,
.ant-select-selected:focus,
.ant-select-selected:hover {
  border: none;
}

/* Inputs */
input.ant-input:read-only,
.ant-select ant-select {
  color: #000;
}

input.ant-input,
.ant-select.ant-select-enabled {
  color: rgb(9, 109, 217);
}

/* Calls */
.callsList .listItem:hover {
  /* box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.2); */
  color: #222;
}

.callsList .listItem.selected {
  /* box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.3); */
  background: #f3f3f3;
  color: #000;
}

.callsList .listItem {
  margin: 10px;
  border-radius: 5px;
  background: #fafafa;
  /* box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.12); */
  border: solid 1px rgb(224, 224, 224);
  transition: box-shadow 0.4s ease-in-out;
}

/* Caser Input */

/*Reset */
textarea,
input.text,
input[type="text"],
input[type="tel"],
input[type="button"],
input[type="submit"],
.input-checkbox {
  -webkit-appearance: none;
  border-radius: 0;
}

.caser-input-container {
  /* border-radius: 5px; */
  margin: 5px;
  flex-grow: 1;
  /* padding: 0px 10px; */
}

.caser-input-label {
  color: #263238;
  letter-spacing: 1.5px;
  background: linear-gradient(to bottom, #5e779e, #365870);
  background: #e9edf0;
  background: transparent;
  border-radius: 5px;
  padding: 3px;
  margin: 0px;
  font-weight: 600;
  letter-spacing: 0px;
  text-align: left;
  font-size: 14px;
}
.caser-input-label:empty {
  display: none;
}
.caser-input-textarea {
  resize: none;
}

input.caser-input,
textarea.caser-input,
div.caser-input-select__control {
  background: #eceff1;
  /* background:linear-gradient(to top, #2f2f2e, #6d6b65); */
  border-radius: 5px;
  /* border-top-left-radius: 0px;
  border-top-right-radius: 0px; */
  padding: 10px;
  width: 100%;
  transition: background-color 1.25s cubic-bezier(0.165, 0.845, 0.485, 1),
    color 0.25s, border-color 0.25s;
  border: solid 1px #eceff1;
  /* border-top: none; */
  /* box-shadow: inset 0px 22px 20px -26px rgba(0, 0, 0, 0.5); */
  min-height: 0px;
  overflow: hidden;
  box-shadow: none;
  text-align: start;
  box-sizing: border-box;
}

.caser-input::placeholder {
  color: #707b8b;
}

/* Caser input text */
.caser-input,
div.caser-input-select__value-container > div {
  font-size: 100%;
  color: #03396c;
  font-weight: 600;
  font-family: sans-serif;
}

div.caser-input-select__value-container > div {
  margin: 0px;
  padding: 0px;
}

.caser-input:focus,
.caser-input:active,
.div.caser-input-select__control--is-focused
  .caser-input-select__value-container {
  color: #195fa1;
  border-color: #e6e9ec;
}

.caser-input:hover,
div.caser-input-select__control:hover {
  border-color: #e6e9ec;
  background: #e6e9ec;

  /* box-shadow: inset 0px 22px 20px -26px rgba(0, 0, 0, 0.5); */
}

/* .caser-input-select__value-container,
.div.caser-input-select__control--is-focused .caser-input-select__value-container, */

.caser-input:read-only,
.caser-input-select.readonly .caser-input-select__single-value,
.caser-input:focus:read-only {
  color: #263238;
  border-color: #eceff1;
  cursor: default;
  background: #eceff1;
}
.caser-input-select.readonly .caser-input-select__indicators {
  display: none;
}

div.caser-input-select__control {
  /* border-top: none; */
  /* margin: 0px; */
  padding: 0px;
  /* user-select: none; */
  /* color: #011f4b; */
}

.caser-input:focus,
.div.caser-input-select__control--is-focused {
  background: #ffffff;
  border-color: #e3e9f0;
  padding: 10px;
  /* box-shadow: inset 0px 22px 20px -26px rgba(0, 0, 0, 0); */
}

div.caser-input-select__value-container {
  padding: 10px 10px;
  /* margin: 0; */
  border: none;
  width: 100%;
  transition: background 0.5s;
  max-height: 36px;
  position: inherit;
}

div.caser-input-select__placeholder {
  padding: 0;
  margin: 0;
  font-weight: 200;
}

div.caser-input-select__option {
  padding: 6px 15px;
}

div.caser-input-select__option--is-focused.caser-input-select__option--is-selected {
  background: #c5cfdaec;
  /* color: #fff; */
}

div.caser-input-select__option--is-selected {
  background: #dce4ecec;
  color: rgb(0, 0, 0);
}

.react-datepicker-wrapper {
  width: 100%;
}

.react-datepicker__input-container {
  width: 100%;
}

.react-datepicker-popper {
  z-index: 999;
}

.react-datepicker__time-container
  .react-datepicker__time
  .react-datepicker__time-box
  ul.react-datepicker__time-list
  li.react-datepicker__time-list-item {
  padding: 5px 0px;
}

.caser-radio-input {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-items: stretch;
  border-radius: 5px;
}
.caser-radio-element {
  padding: 5px;
  margin: 3px;
  flex-grow: 1;
  text-align: center;
  border: solid thin transparent;
  border-radius: 5px;
  transition: background-color 1s cubic-bezier(0.19, 1, 0.22, 1),
    border-color 1s cubic-bezier(0.19, 1, 0.22, 1);
  cursor: pointer;
}
.caser-radio-element:hover {
  border-color: #333;
}
.caser-radio-element.selected {
  border-color: #0063b1;
  background-color: #fff;
}
/*Field Group*/
.caser-field-Group {
  display: inline-flex;
  width: 100%;
}

.caser-field-Group .caser-input-label {
  background: linear-gradient(to bottom, #0e3442 10%, #1d5468 30%);
  background: transparent;
  color: #505050;
  border-radius: 0px;
  /* border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px; */
  margin: 0px 10px;
  padding: 0px;
  font-size: 12px;
  /* width: fit-content; */
  margin-bottom: 3px;
}

.caser-field-Group .caser-input-container,
.caser-field-Group-after-first .caser-input-container {
  border-radius: 0px;
  margin: 0px;
  padding: 0px 0px;
}

.caser-field-Group .caser-input,
.caser-field-Group .caser-input-select__control {
  border-radius: 0px 0px 0px 0px;
  min-height: 0px;
}

.caser-field-Group-after-first .caser-input-container:first-child .caser-input {
  border-radius: 5px 0px 0px 5px;
  /* border-top: solid 1px #bbb; */
}

.caser-field-Group .caser-input-container:first-child .caser-input,
.caser-field-Group
  .caser-input-container:first-child
  .caser-input-select__control {
  border-bottom-left-radius: 5px;
  border-top-left-radius: 5px;
}

.caser-field-Group .caser-input-container:last-child .caser-input {
  border-bottom-right-radius: 5px;
  border-top-right-radius: 5px;
}

.caser-input-phone-container .caser-input-select__indicators {
  display: none;
}

/* .caser-input-select__control .caser-input-select__indicators {
  display: none;
} */

.caser-input-select__control .caser-input-select__single-value {
  /* margin: 10px 0px; */
}

/* Card */
.caser-card {
  border-radius: 5px;
  margin: 0px auto;
  margin: 5px;
  margin-bottom: 25px;
  position: relative;
  /* max-width: 1000px; */
}
.caser-card-header,
.caser-card-footer {
  border-radius: 5px;
  background: #03396c;
  letter-spacing: 1px;
  font-variant: small-caps;
  padding: 7px;
  font-size: 130%;
  color: rgb(255, 255, 255);
  flex-wrap: wrap;
}
.caser-card-footer {
  border-radius: 5px;
  background: #dfe0e2;
  letter-spacing: 1px;
  font-variant: normal;
  padding: 3px 7px;
  font-size: 110%;
  color: rgb(255, 255, 255);
  flex-wrap: wrap;
}
.caser-card-body {
  padding: 10px 10px;
}

.caser-card-body:empty {
  display: none;
}

.caser-card-body-inner {
  padding: 10px;
  margin: -3px;
  /* box-shadow: inset 0px 10px 10px -7px rgba(0, 0, 0, 0.55); */
  margin-top: -10px;
  background: #fff;
  border-radius: 0px 0px 5px 5px;
  border: solid 1px #03396c;
  border-top: none;
}
.caser-card-with-footer .caser-card-body-inner {
  margin-bottom: -10px;
  border-bottom: none;
  border-radius: 0px 0px 0px 0px;
}

/* Collapsable */
.caser-collapsable-container {
  border-radius: 5px;
  margin: 10px;
}

.caser-collapsable-element-header {
  border-radius: 4px;
  background: #eee;
  /* background: #0099BC; */
  color: rgb(0, 0, 0);

  padding: 11px 9px;
  margin-top: 7px;
  transition: border-radius 0.45s cubic-bezier(0.19, 1, 0.22, 1),
    margin 0.45s cubic-bezier(0.19, 1, 0.22, 1), background-color 0.45s;
  cursor: pointer;
  letter-spacing: 1px;
  font-weight: 800;
  user-select: none;
  margin: 5px 0px;
}
.caser-collapsable-element-header:hover {
  background: #ddd;
}
.caser-collapsable-element-header[disabled] {
  background: #cfd8dc;
  color: #6e6e6e;
}

.caser-collapsable-element.first,
.caser-collapsable-element.first > .caser-collapsable-element-header {
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  margin-top: 0px;
}

.caser-collapsable-element.last,
.caser-collapsable-element.last > .caser-collapsable-element-header {
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}

.caser-collapsable-element.open {
  /* margin-top:5px; */
}

.caser-collapsable-element.open
  + .caser-collapsable-element
  .caser-collapsable-element-header {
  margin-top: 0px;
}

.caser-collapsable-element.open > .caser-collapsable-element-header {
  border-radius: 7px;
  /* margin-top: 5px; */
  background: #0078d7;
  color: #fff;

  /* background: linear-gradient(to bottom, #005b96, #004574); */
}

.caser-collapsable-element.open > .caser-collapsable-element-header[disabled] {
  background: rgb(190, 199, 202);
  color: #444444;
}

.caser-collapsable-element.open.first > .caser-collapsable-element-header,
.caser-collapsable-element.first > .caser-collapsable-element-header {
  margin-top: 0px;
}

.caser-collapsable-element-body {
  opacity: 0;
  height: 0;
  transition: padding 0.45s cubic-bezier(0.19, 1, 0.22, 1),
    box-shadow 0.45s cubic-bezier(0.19, 1, 0.22, 1),
    border-color 0.45s cubic-bezier(0.19, 1, 0.22, 1);
  overflow: hidden;
  padding: 0px 10px;
  color: #000;
  border: none;
  border-left: dotted 1px;
  border-right: dotted 1px;
  border-color: transparent;
  margin: 0px 7px;
  /* box-shadow: inset 0px 10px 10px -7px transparent; */
}

.caser-collapsable-element.open.last > .caser-collapsable-element-body {
  border-bottom: dotted 1px;
  border-radius: 0px 0px 5px 5px;
}

.caser-collapsable-element.open > .caser-collapsable-element-body {
  opacity: 1;
  height: auto;
  padding: 10px 10px;
  border-color: #004574;
  /* box-shadow: inset 0px 7px 7px -7px #004574; */
}

/* Caser Button*/
.caser-button {
  border-radius: 5px;
  border: 1px solid transparent;
  vertical-align: middle;
  padding: 6px 8px;
  height: 27px;
  line-height: 15px;
  margin: 0px 5px;
  font-size: 15px;
  transition: border-color 0.2s, color 0.2s;
  cursor: pointer;
  text-align: center;
  user-select: none;
  white-space: nowrap;
}

.caser-button[shape="circle"] {
  width: 27px;
  border-radius: 15px;
  padding: 7px 0px;
}

.caser-button[type="default"] {
  border-color: #ccc;
  background: #eee;
  color: #000;
}

.caser-button[type="default"]:hover {
  background: #fff;
}

.caser-button[type="default"]:active {
  border-color: #666;
  background: #fafafa;
}

.caser-button[type="default"]:disabled {
  background: #dddddd;
  color: #777;
  cursor: not-allowed;
}

.caser-button[noborder] {
  border-color: none;
}

.caser-button[type="ghost"] {
  background: transparent;
  color: inherit;
}

.caser-button[type="ghost"]:hover {
  color: shade(inherit, 20%);
}

.caser-button[type="ghost"]:active {
  color: shade(inherit, 30%);
}

.caser-button[type="ghost"]:disabled {
  color: #777;
  cursor: not-allowed;
}

.caser-button[type="primary"] {
  border-color: #0078d7;
  background: #0063b1;
  color: #fff;
}

.caser-button[type="primary"]:hover {
  background: #0078d7;
}

.caser-button[type="primary"]:active {
  border-color: #008cff;
  background: #0071ce;
}

.caser-button[type="primary"]:disabled {
  background: #1a88e2;
  color: #78baf0;
}

.caser-button:disabled {
  cursor: not-allowed;
}

/* Caser Title Date */
.caser-title-date {
  background: #2d7d9a;
  padding: 2px 4px;
  border-radius: 5px;
  display: inline-block;
  margin: 0px 5px;
}

/*FABs*/

button.MuiFab-root {
  margin: 10px;
}
